import { useMemo } from "react";
import { useTheme, createTheme } from "@mui/material";

const useThemeOptions = () => {
  const defaultTheme = useTheme();
  return useMemo(() => createTheme({
    ...defaultTheme,
    palette: {
      ...defaultTheme.palette,
      primary: {
        main: "#ffffff",
        light: "#ffffff",
        dark: "#efefef",
      },
      secondary: {
        main: "#74af0d",
        light: "#a7e14b",
        dark: "#417f00",
      },
      text: {
        ...defaultTheme.palette.text,
        primary: "#ffffff",
        secondary: "#000000",
      },
      background: {
        ...defaultTheme.palette.background,
        paper: "#363636",
      },
      divider: "#606060",
    },
    typography: {
      fontSize: 15,
    },
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& >fieldset": {
              border: "1px solid #efefef",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&.Mui-disabled": {
              color: "rgba(255, 255, 255, 0.26)",
              border: "1px solid rgba(255, 255, 255, 0.26)",
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "rgba(255, 255, 255, 0.26)",
          },
        },
      },
    },
  }), [defaultTheme]);
};

export default useThemeOptions;
