import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import HomePage from './pages';
import ModelPage from './pages/model';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/model/:modelCode" element={<ModelPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
