import { useCallback, useMemo } from "react";
import { invalidate, useFrame, useThree } from "@react-three/fiber";
import { CubeTexture, NearestFilter, PerspectiveCamera, WebGLRenderTarget } from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { CubeTexturePass } from "three/examples/jsm/postprocessing/CubeTexturePass";
import { SAOPass } from "three/examples/jsm/postprocessing/SAOPass";
import { CopyShader } from "three/examples/jsm/shaders/CopyShader";
import { SMAAPass } from "three/examples/jsm/postprocessing/SMAAPass";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader";
import { useSpring } from "react-spring";

var AlphaOverShader = {
  uniforms: {
    "tDiffuse1": { value: null },
    "tDiffuse2": { value: null },
  },
  vertexShader: /* glsl */`
		varying vec2 vUv;

		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,
  fragmentShader: /* glsl */`
		uniform sampler2D tDiffuse1;
		uniform sampler2D tDiffuse2;

		varying vec2 vUv;

		void main() {
			vec4 texel1 = texture2D( tDiffuse1, vUv );
			vec4 texel2 = texture2D( tDiffuse2, vUv );
      gl_FragColor.rgb = mix(texel1.rgb, texel2.rgb, texel2.a);
      gl_FragColor.a = min(texel1.a + texel2.a, 1.0);
		}`
};

export default function RenderEffects({ enabled }: { enabled: boolean }) {
  const { scene, gl, size, camera } = useThree();
  const saoIntensity = 0.0125;

  const backgroundPass = useMemo(() => {
    const createCubeCanvas = (colors: [string, string, string, string]) => {
      const canvas = document.createElement("canvas");
      canvas.width = 2;
      canvas.height = canvas.width;
      const context = canvas.getContext("2d");

      if (!context) throw new Error("Could not create canvas");
      context.fillStyle = colors[0];
      context.fillRect(0, 0, 1, 1);
      context.fillStyle = colors[1];
      context.fillRect(1, 0, 1, 1);
      context.fillStyle = colors[2];
      context.fillRect(0, 1, 1, 1);
      context.fillStyle = colors[3];
      context.fillRect(1, 1, 1, 1);
      return canvas;
    };

    const horizonUpperColor = "#ffffff";
    const horizonLowerColor = "#eceeec"
    const horizontalCanvas = createCubeCanvas([horizonUpperColor, horizonUpperColor, horizonLowerColor, horizonLowerColor]);
    const topCanvas = createCubeCanvas([horizonUpperColor, horizonUpperColor, horizonUpperColor, horizonUpperColor]);
    const bottomCanvas = createCubeCanvas([horizonLowerColor, horizonLowerColor, horizonLowerColor, horizonLowerColor]);
    const tex = new CubeTexture([horizontalCanvas, horizontalCanvas, topCanvas, bottomCanvas, horizontalCanvas, horizontalCanvas], undefined, undefined, undefined, NearestFilter, NearestFilter, undefined, undefined, undefined);
    tex.needsUpdate = true;
    const p = new CubeTexturePass(camera as PerspectiveCamera, tex);
    return p;
  }, [camera]);
  const renderPass = useMemo(() => new RenderPass(scene, camera), [scene, camera]);
  const saoPass = useMemo(() => {
    const p = new SAOPass(scene, camera, false, true);
    p.params.saoScale = 10;
    p.params.saoIntensity = saoIntensity;
    p.params.saoKernelRadius = 25;
    p.params.saoBlurStdDev = 1;
    return p;
  }, [scene, camera]);

  const smaaPass = useMemo(() => new SMAAPass(size.width, size.height), [size]);
  const gammaCorrectionPass = useMemo(() => new ShaderPass(GammaCorrectionShader), []);

  const [backgroundComposer, backgroundTarget] = useMemo(() => {
    const t = new WebGLRenderTarget(size.width, size.height);
    const c = new EffectComposer(gl, t);
    c.setPixelRatio(window.devicePixelRatio);
    c.renderToScreen = false;
    c.addPass(backgroundPass as any);
    const copyPass = new ShaderPass(CopyShader);
    c.addPass(copyPass);
    return [c, t];
  }, [gl, backgroundPass, size]);

  const [foregroundComposer, foregroundTarget] = useMemo(() => {
    const t = new WebGLRenderTarget(size.width, size.height);
    const c = new EffectComposer(gl, t);
    c.setPixelRatio(window.devicePixelRatio);
    c.renderToScreen = false;
    c.addPass(renderPass);
    c.addPass(saoPass);
    c.addPass(gammaCorrectionPass);
    c.addPass(new ShaderPass(CopyShader));
    return [c, t];
  }, [gl, renderPass, saoPass, gammaCorrectionPass, size]);

  const blendComposer = useMemo(() => {
    const blendShader = new ShaderPass(AlphaOverShader);
    blendShader.uniforms.tDiffuse1.value = backgroundTarget.texture;
    blendShader.uniforms.tDiffuse2.value = foregroundTarget.texture;

    const c = new EffectComposer(gl);
    c.setPixelRatio(window.devicePixelRatio);
    c.renderToScreen = true;
    c.addPass(blendShader);
    c.addPass(smaaPass);
    return c;
  }, [gl, backgroundTarget, foregroundTarget, smaaPass]);

  useFrame(() => {
    backgroundComposer.render();
    foregroundComposer.render();
    blendComposer.render();
  }, 1);

  const setAOEnabled = useCallback((enabled: boolean) => {
    saoPass.enabled = enabled;
  }, [saoPass]);

  useSpring({
    saoIntensity: enabled ? saoIntensity : 0,
    onChange: val => {
      saoPass.params.saoIntensity = val.value.saoIntensity;
      invalidate();
    },
    onStart: () => {
      setAOEnabled(true);
      invalidate();
    },
    onRest: val => {
      setAOEnabled(val.value.saoIntensity !== 0);
      invalidate();
    },
  });

  return null;
}
